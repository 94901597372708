import React from 'react'
import _ from 'lodash'
import Posts from '../components/Posts'

export default ({
  pageContext: {
    edges,
  },
}) => (
  <Posts
    title='Tekstai'
    edges={_.filter(edges, {
      node: {
        is_video: false,
      },
    })}
  />
)
